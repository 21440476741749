import React from 'react';
import "./card.css";


import Instagram from '../../../assets/images/instagram.png';
import Facebook from '../../../assets/images/facebook.png';
import Whatsapp from '../../../assets/images/whatsapp.png';
import Telegram from '../../../assets/images/telegram.png';

const CardFancy = ({ title, title2, description, listItem1, listItem2, listItem3, listItem4, listItem5, listItem6, middletitle, middledescription}) => {
  return (

    <div style={{backgroundColor:'#202020', borderRadius:'8px', padding:'25px'}}>

    <div className="w-100">

    <div class="text-2xl font-medium relative p-5 text-center uppercase">{title} <span class="text-2xl font-extrabold text-transparent bg-clip-text bg-gradient-to-l to-custom-gradient-to from-custom-gradient-from pb-2">{title2}</span>
          </div>

       <div>
        <p className="py-2 text-base text-center">{description}</p>
                
        <ul className="py-2 px-2">
            <li><i className="fa fa-angle-double-right"></i> {listItem1}</li>
            <li><i className="fa fa-angle-double-right"></i> {listItem2}</li>
            <li><i className="fa fa-angle-double-right"></i> {listItem3}</li>
            <li><i className="fa fa-angle-double-right"></i> {listItem4}</li>
            <li><i className="fa fa fa-angle-double-right"></i> {listItem5}</li>
            <li><i className="fa fa fa-angle-double-right"></i> {listItem6}</li>
        </ul>
        <div className="xl:text-xl text-center w-2/3 m-auto pb-3 font-semibold"><br/><span className="text-base"> {middledescription}</span></div>
        <div className="ml-5 mt-7">Contact Us</div>
           <div className="flex-col justify-center items-center">
          <div className="flex justify-center mt-2">
            <span className="text-white hover:bg-gradient-to-r hover:from-cyan-500 hover:to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg h-12 p-2 mr-3">
            <a href="https://www.instagram.com/thestation.tv/" alt="the station tv message us" target="new"><img src={Instagram} alt="The Station TV Instagram" title="The Station TV Instagram" className="icons" /><span className="icons-name">Instagram</span></a>
            </span>
            <span className="text-white hover:bg-gradient-to-r hover:from-cyan-500 hover:to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg h-12 p-2 mr-3">
            <a href="https://www.facebook.com/thestation.tv/" alt="the station tv message us" target="new"><img src={Facebook} alt="The Station TV Facebook" title="The Station TV Facebook" className="icons" /><span className="icons-name">Facebook</span></a>
            </span>
            <span className="text-white hover:bg-gradient-to-r hover:from-cyan-500 hover:to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg h-12 p-2 mr-3">
            <a href="https://wa.me/17145972178" alt="the station tv message us" target="new"><img src={Whatsapp} alt="The Station TV Facebook" title="The Station TV Facebook" className="icons" /><span className="icons-name">Whatsapp</span></a>
            </span>
            <span className="text-white hover:bg-gradient-to-r hover:from-cyan-500 hover:to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg h-12 p-2 mr-3">
            <a href="https://t.me/+6EW5A2HM378yNTlh" alt="the station tv message us" target="new"><img src={Telegram} alt="The Station TV Facebook" title="The Station TV Facebook" className="icons" /><span className="icons-name">Telegram</span></a>
            </span>
          </div>
          </div>
        
        </div>
        </div>
    </div>
  )
}

export default CardFancy;