import React from 'react';
import MobileTopNav from "./MobileTopNav";
import MobileFooter from "./MobileFooter";


const MobileServices = () => {
  return (
    <div>
        <MobileTopNav />
        <div className="middle-content">
            
            <h2 style={{fontSize:'20px', fontWeight:'bold', textTransform:'uppercase'}}>Our Promotional Services</h2>
        </div>
    
        <div className="px-44 my-10" style={{marginBottom:'175px'}}>
        <div style={{marginTop:'25px', fontSize:'14px', fontWeight:'bold', textTransform:'uppercase'}}>ARTIST & CREATORS PROMOTIONAL SERVICES
        </div>

          <p style={{color:'orangered'}}>2024 will be the year of closing gaps in our knowledge of the Industry and we plan to share all we learn with our members. We are reading, learning, asking the right questions from the right people in the upper levels of the Music and Entertainment Industry the right questions to fill those gaps. Slowly we will be adding more services that are less focused on the digital world and more focused on hitting the pavement and talking to those in the know. Please check back here and there as we will update when shows from these professional are available. We hope to have many live interviews where our viewers can participate in the forum. Also, join our newsletter below for information as it comes in.</p>
          

            <div style={{backgroundColor:'#222222', padding:'25px'}}>
               <h2>Music Videos</h2>
               <p>Need a music video recorded or even streamed? All members get a 50% discount on video production including editing.</p>
            <p><a href="mailto:media@thestationtv.com" alt="music videos">Contact Us For More Info</a></p>
            </div>

            <div style={{backgroundColor:'#222222', padding:'25px', borderTop:'1px solid #666'}}>
               <h2>Creator Stage For Video</h2>
               <p>Do you have a show idea and would like us to help film your show or video. We can come to you or you can book our stage at our Artists & Creators Streaming Retreat in Joshua Tree.</p>
               <p><a href="mailto:media@thestationtv.com" alt="music videos">Contact Us For More Info</a></p>
            </div>

            <div style={{backgroundColor:'#222222', padding:'25px', borderTop:'1px solid #666'}}>
               <h2>Artists Stage For Events & Concerts</h2>
               <p>Depending on what type of show you would like (we can discuss) you can book our stage at our Artists & Creators Streaming Retreat in Joshua Tree.</p>
               <p><a href="mailto:media@thestationtv.com" alt="music videos">Contact Us For More Info</a></p>
            </div>

            <div style={{backgroundColor:'#222222', padding:'25px', borderTop:'1px solid #666'}}>
               <h2>Become a Pro Member and we will help share your socials and more!</h2>
               <p>Our <a href="mobile-submit-media">Pro Membership</a> is very reasonable for all that we do for you. We have major plans so please follow us on our socials to keep up to date with the happenings.</p>
               <p><a href="mailto:media@thestationtv.com" alt="music videos">Contact Us For More Info</a></p>
            </div>
            </div>
            <MobileFooter />
    </div>
  )
}

export default MobileServices;