import React from 'react';
import Logo from "../../assets/images/sm_overhang_logo.png";
import { useUserAuth } from "../../context/UserAuthContext";



const MobileTopNav = () => {

    const { user } = useUserAuth();

   // console.log("this is user ", user)

    return (

<div style={{display:'flex', justifyContent:'space-around'}}>
<div className="mobile-topnav"><a href="/" alt="the station tv"><img src={Logo} alt="the station tv" height="48px" /></a></div>
    {user === null ? 
    (         
        <div style={{marginTop:'35px'}}>Welcome</div>
    ) : (

<div>
    
{user.displayName === "Director" ? ( 
            <div style={{marginTop:'35px'}}><a href="/mobile-admin-dashboard"><i className="fa fa-lock"></i> {user.displayName} </a></div>
           
             ):( 
                <div style={{marginTop:'35px'}}> <a href="/mobile-member-dashboard" style={{color:'white'}}><i className="fa fa-unlock"></i> {user.displayName} </a></div>
               
              )} 
          
</div>
    )}
</div>


)}

export default MobileTopNav;