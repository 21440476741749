import React, { useState, useEffect } from 'react';
import { collection, getDocs } from "firebase/firestore";
import {db} from '../../../firebase';
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router";
import { useUserAuth } from "../../../context/UserAuthContext";

import Popup from 'reactjs-popup';

import Logo from "../../../assets/images/sm_overhang_logo.png";
import Instagram from '../../../assets/images/instagram.png';
import Facebook from '../../../assets/images/facebook.png';
import Whatsapp from '../../../assets/images/whatsapp.png';
import Telegram from '../../../assets/images/telegram.png';

import "./core.css";

const TopNav = () => {

  const { logOut, user } = useUserAuth();

  const [open, setOpen] = useState(false);  
  const closeModal = () => setOpen(false);

  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  const [liveStatus, setLiveStatus] = useState();

  console.log("this is live status ", liveStatus)

  const fetchLive = async () => {
       
    await getDocs(collection(db, "Controlroom"))
        .then((querySnapshot)=>{               
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
            setLiveStatus(newData[0].MainStageLive);
        })
   
}

useEffect(()=>{
    fetchLive();
}, [])

  return (
    <div>
      <div className="header">
        <div className="header-wrap padded-container">
          <nav className="header-nav">
          <a href="/" alt="the station tv" className="site-logo"><img src={Logo} alt="the station tv" className="site-logo" /></a>
              <ul className="navigation header-nav--items">

                 { !liveStatus === true ?
        (
              <li>                
             <button type="button" onClick={() => setOpen(o => !o)} style={{color:'white', backgroundColor:'gray', border:'0px', padding:'5px 7px', paddingBottom:'5px', marginTop:'3px', borderRadius:'5px'}}>WATCH LIVE </button>
            </li>

            ) : ( 
             
              <li>                
              <a href="/live" alt="Main streaming stage" style={{backgroundColor:'red', padding:'10px', borderRadius:'5px'}}>WATCH LIVE </a>
            </li>
           )}
                
              <li>                
           <a href="/mainstage" alt="Main streaming stage"> WATCH</a>
            </li>
        
        
            <li>                
           <a href="/audiocast" alt="Main streaming stage"> LISTEN</a>
            </li>

            <li>
            <a href={`/tv-guide`}> GUIDE</a>
         </li>

         <li>
            <a href={`/joinGeneral`}> SUBMIT</a>
         </li>
               
              </ul>
          </nav>
          {!user ? (
          <ul className="header-account header-nav--items">
            <li><a href="/login"> SIGN IN </a>
            </li>
            <li><a href="/signup" className="signup"> SIGN UP </a>
            </li>
          </ul>
          ) : (
            
            
            
            <ul className="header-account header-nav--items">
       
          {user.displayName === "Director" ? ( 
            <li><a href="/admin-dashboard"> {user.displayName} </a>
            </li>
             ):( 
               <li><a href="/member-dashboard"> {user.displayName} </a>
                </li> 
              )} 
            <li>
              <Button variant="success" style={{backgroundColor:'#002fff', borderRadius:'5px'}} onClick={handleLogout}>
            LOGOUT
          </Button>
            </li>
          </ul>
          )}

        </div>

        <Popup open={open} closeOnDocumentClick onClose={closeModal}>
              <div className="tip-modal-big">
                <a href="" className="close" onClick={closeModal}> &times; </a>
            
                <div className="content">Sorry, we are not currently live. The button will change to <span className="text-red-500">red</span> when we are ready or in the process of going live.<br/><br/>Upcoming Live Streams will be announced via our social media pages. Please join us on social media to find out about when we are planning a live event.</div>
         
                <div className="flex justify-center">
         
                <div className="flex justify-center mt-2">
            <span className="text-white rounded-lg h-12 p-2 mr-3">
            <a href="https://www.instagram.com/thestation.tv/" alt="the station tv message us" target="new"><img src={Instagram} alt="The Station TV Instagram" title="The Station TV Instagram" className="icons" /><span className="icons-name">Instagram</span></a>
            </span>
            <span className="text-white rounded-lg h-12 p-2 mr-3">
            <a href="https://www.facebook.com/thestation.tv/" alt="the station tv message us" target="new"><img src={Facebook} alt="The Station TV Facebook" title="The Station TV Facebook" className="icons" /><span className="icons-name">Facebook</span></a>
            </span>
            <span className="text-white rounded-lg h-12 p-2 mr-3">
            <a href="https://wa.me/17145972178" alt="the station tv message us" target="new"><img src={Whatsapp} alt="The Station TV Facebook" title="The Station TV Facebook" className="icons" /><span className="icons-name">Whatsapp</span></a>
            </span>
            <span className="text-white rounded-lg h-12 p-2 mr-3">
            <a href="https://t.me/+6EW5A2HM378yNTlh" alt="the station tv message us" target="new"><img src={Telegram} alt="The Station TV Facebook" title="The Station TV Facebook" className="icons" /><span className="icons-name">Telegram</span></a>
            </span>
          
          </div>
               </div>
               </div>
          </Popup>

      </div>
    </div>
  )
}

export default TopNav