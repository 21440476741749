import React, { useState } from 'react';
import { Alert, Form, Button } from "react-bootstrap";
import { useUserAuth } from "../../../../context/UserAuthContext";
import TopNav from '../../core/TopNav';
import Footer from '../../core/Footer';

import "./member.css";

import {
    updateProfile
  } from "firebase/auth";

const MemberDashboard = () => {

    const { user } = useUserAuth();
    const [name, setName] = useState('');
    const [error, setError] = useState('');

    //console.log("this is name before submit ", name)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        try {
            updateProfile(user, {
                displayName: name, 
                photoURL: "https://example.com/jane-q-user/profile.jpg"
            })
          //  console.log("its updated i guess")
        } catch (err) {
          setError(err.message);
        }
      };

    //  console.log("this is user display name i guess ", user.displayName)

    
    return (
      <>
      <TopNav />
      <div className="middle-content extra-top">
      <div style={{padding:'25px'}}>
        <h2>Member Dashboard</h2>

        <div style={{display:'flex', justifyContent:'space-around', padding:'25px', backgroundColor:'#222'}}>
        <div>
            <div>
                <h3>Your Profile</h3> 
                
                {!user.displayName ? (
                <div>Add Display Name</div>
                ) : (
                <div>{user.displayName}</div>
                )}
            </div>
            
            <div>{user.email}</div>

        </div>
<div>
           <a href="https://livestudio.dreamhosters.com/vdo.ninja-develop/" className="bg-transparent hover:bg-slate-500 text-slate-400 font-semibold hover:text-white py-2 px-4 border border-slate-400 hover:border-transparent rounded">Enter Live Studio</a>
          </div>
        
          </div>

          <div style={{width:'325px', margin:'auto', paddingTop:'25px'}}>
          {error && <Alert variant="danger">{error}</Alert>}
            <h4>Update Profile</h4>

                <Form onSubmit={handleSubmit}>

                    <Form.Group className="mb-3" controlId="formFullName">
                        <Form.Label>Display Name</Form.Label>
                        <Form.Control
                        type="text"
                        placeholder="Name"
                        onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                    <div className="d-grid gap-2">
                        <Button variant="secondary" type="Submit">
                        Update Profile
                        </Button>
                    </div>
                </Form>
          </div>

            </div>
            </div>
        <Footer />
        </>
    )
}

export default MemberDashboard;