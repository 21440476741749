import React from 'react';
import Card from './CardFancy';
import TopNav from "./TopNav";
import Footer from "./Footer";


const Services = () => {
  return (
    <div>
        <TopNav />
        <div className="middle-content extra-top">
            
            <h2 style={{fontSize:'22px', fontWeight:'bold', textTransform:'uppercase'}}>ARTIST & CREATORS PROMOTIONAL SERVICES</h2></div>
            <div style={{width:'75%', backgroundColor:'#191919', borderRadius:'7px', padding:'25px', margin:'auto', textAlign:'center', marginTop:'25px', marginBottom:'25px'}}>
            <div style={{display:'flex', justifyContent:'space-evenly'}}>
                <div className="star">*</div>
                <div style={{width:'75%'}}>Publishers! We are putting together a sync library! Looking for music, songwriters, bands for your next event or a new act for your Label? Contact Us and we will set you up with a VIP account so you can listen and view our artists and creators library, download EPK's and contact artists for your tv shows, commercials, movies or events.</div>
            </div>
            </div>

            <div>
 
          <p style={{color:'orangered'}}>2024 will be the year of closing gaps in our knowledge of the Industry and we plan to share all we learn with our members. We are reading, learning, asking the right questions from the right people in the upper levels of the Music and Entertainment Industry the right questions to fill those gaps. Slowly we will be adding more services that are less focused on the digital world and more focused on hitting the pavement and talking to those in the know. Please check back here and there as we will update when shows from these professional are available. We hope to have many live interviews where our viewers can participate in the forum. Also, join our newsletter below for information as it comes in.</p>
          

            <div style={{backgroundColor:'#222222', padding:'25px'}}>
               <h2>Music Videos</h2>
               <p>Need a music video recorded or even streamed? All members get a 50% discount on video production including editing.</p>
            <p><a href="mailto:media@thestationtv.com" alt="music videos">Contact Us For More Info</a></p>
            </div>

            <div style={{backgroundColor:'#222222', padding:'25px', borderTop:'1px solid #666'}}>
               <h2>Creator Stage For Video</h2>
               <p>Do you have a show idea and would like us to help film your show or video. We can come to you or you can book our stage at our Artists & Creators Streaming Retreat in Joshua Tree.</p>
               <p><a href="mailto:media@thestationtv.com" alt="music videos">Contact Us For More Info</a></p>
            </div>

            <div style={{backgroundColor:'#222222', padding:'25px', borderTop:'1px solid #666'}}>
               <h2>Artists Stage For Events & Concerts</h2>
               <p>Depending on what type of show you would like (we can discuss) you can book our stage at our Artists & Creators Streaming Retreat in Joshua Tree.</p>
               <p><a href="mailto:media@thestationtv.com" alt="music videos">Contact Us For More Info</a></p>
            </div>

            <div style={{backgroundColor:'#222222', padding:'25px', borderTop:'1px solid #666'}}>
               <h2>Become a Pro Member and we will help share your socials and more!</h2>
               <p>Our <a href="mobile-submit-media">Pro Membership</a> is very reasonable for all that we do for you. We have major plans so please follow us on our socials to keep up to date with the happenings.</p>
               <p><a href="mailto:media@thestationtv.com" alt="music videos">Contact Us For More Info</a></p>
            </div>

            </div>
            

<div style={{fontSize:'18px', fontWeight:'bold', textTransform:'uppercase'}}>DIGITAL &amp; PRINT MARKETING
</div>

<div>
 
            <div className="flex justify-center">
                <Card
                    title="Digital Media Marketing"
                    title2="Services & Consulting"
                    description="All campaigns start out with a thorough analysis of where you are at in your digital marketing venture. Once this is determined we start a report from your current social profiles in order to determine what ads or posts are needed as well as the most effective posting schedule for your audience."
                    listItem1="Web / Mobile Design &amp; Development"
                    listItem2="Website Management & Updates"
                    listItem3="Search Engine Optimization (SEO)"
                    listItem4="Content Strategy and Writing"
                    listItem5="Online Reputation Management (ORM)"
                    listItem6="Social Media Marketing"
                    middletitle="SCHEDULING"
                    middledescription="Once reviewed and satisfied we then run campaigns specific to your project. We will work with you to create focused high quality content to grow your following and guarantee engagement. We also set you up with scheduled email campaigns in order to keep your following engaged.                    
                    All campaigns, digital and non digital will work hand in hand."
                />
            </div>

<div className="flex justify-center my-3">
                <Card
                    title="SUBMIT YOUR"
                    title2="MEDIA &amp; EVENT"
                    description="Send your media to us and we'll put you on The Station TV. We can also help you decide which target audience you want to reach with full media submission to platforms like Spotify as well as give advice on studio quality, marketability and branding."
                    listItem1="Audio Submission To All Major Platforms"
                    listItem2="Video Submissions To All Major Platforms"
                    listItem3="Blog &amp; Article Submissions"
                    listItem4="Search Engine Directory Listings"
                    listItem5="Festival &amp; Event Listings"
                    listItem6="Press Release Submissions"
                    middletitle="Musicians &amp; Live Commerce"
                    middledescription=""
                />
            </div>

<div className="flex justify-center my-3">
                <Card
                    title="WEB &amp; MOBILE"
                    title2="DESIGN &amp; DEVELOPMENT"
                    description="With over 20 years experience in the Web Industry we can create, update, check for ease of use or simply make some suggestions for your current website. We also provide full Internet Presence Evaluation and bury any bad press! We will also evaluate your search engine results and help bump you higher in the rankings."
                    listItem1="Web / Mobile Design &amp; Development"
                    listItem2="Website Management & Updates"
                    listItem3="Branding or Rebrand Designs"
                    listItem4="Ecommerce or Live Commerce"
                    listItem5="Audio/Video Web Production"
                    listItem6="Software Development"
                />
            </div>

            <div className="flex justify-center my-3">
                <Card
                    title="LIVE STREAM YOUR"
                    title2="SHOW OR EVENT"
                    description="Engage with your fans worldwide. We create a custom streaming experience for you. We can either bring in our own camera production team or we can show you how to use your cell phone to stream remotely to our in-house control room. From there our director controls all overlays, promotional graphics, QR Code for instant artist, creator or event payment, ads for the venue you are playing at, all your promotion tags and links and streams the show directly to The Station TV."
                    listItem1="Interact with your FANS with Live Interviews."
                    listItem2="Live or VOD Recording"
                    listItem3="Live Commerce to sell your merch"
                    listItem4="Remote Event Streaming"
                    listItem5="In House Event Streaming Installation"
                    listItem6="Back stage interviews"
                />
            </div>

            </div>
            <Footer />
    </div>
  )
}

export default Services;