import React, { useState } from "react";
import { Alert, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useUserAuth } from "../../../context/UserAuthContext";
import TopNav from "../core/TopNav";
import Footer from "../core/Footer";

const JoinGeneral = () => {
     

  return (
<div>
      <TopNav />
      <div className="middle-content extra-top container">
  
   
        <h3 style={{marginTop:'25px'}}>Submit Your Material</h3>
     
<div style={{marginTop:'15px'}}>
        <div>

        <div style={{padding:'15px'}}><h4>Submission Guidelines</h4>
          <p> We accept all styles and genres. </p>
        </div>


        <div style={{padding:'15px'}}><h4>Video Requirements</h4>
          <p>Please make sure the quality of what you send us is "Roku Quality" or "Radio Quality". For your profile images please send high quality images and we will crop to fit our look.</p>
        </div>

        <div style={{padding:'15px'}}><h4>Marketing Reciprication</h4>
          <h3 style={{textAlign:'center', fontFamily:'cursive'}}>Sharing is Caring</h3>
          In order to make this work for all members we <b>strongly</b> request that you actively promote yourself as well as The Station TV on your social pages while we actively promote you on our social pages. In the old days this was called Link Exchange but now it is a social media concept. We will in turn share you on our social media platforms and depending on your membership level we will add you to our extensive scheduled social postings. When you see a post from us please share to your socials.  and that is how we all get noticed.
        </div>
          
          <div style={{padding:'15px'}}><h4>The Station TV Roku Channel</h4>** To be listed on Roku we require a <a href="/joinPro" alt="join pro membership at the station tv">Pro Membership</a>. The Pro Membership will feature you on any of our live streaming shows, interviews, social media posts, extensive promoting or publishing services. In certain cases we give away spots for free. At the very least please join our newsletter for more information.</div>
       

          <div style={{padding:'55px'}}>
          <h4>What We Need From You:</h4>
            <p>Note: if you have an EPK (electronic press kit) please send it over when you email.</p>
            <p style={{color:'orange'}}>All recordings must be Roku and/or Radio quality!</p>
            <h4>For Video</h4>                    
                    <ol>
                    <li style={{padding:'9px'}}>Current Video<br/>We will need your most recent '<span style={{textTransform:"uppercase"}}>'streaming platform quality video"</span> (not social media, that includes youtube as they compress everything). Once you email us with your information we will set you up a dropbox to upload your video to. Please contact if you have any questions about codecs. </li>

                    <li style={{padding:'9px'}}>Short Description of your project<br/><span className="italic text-sm">* Please no longer than one paragraph.</span> </li>
                    
                    <li style={{padding:'9px'}}>Your social media links<br/><span className="italic text-sm">* If you have linkt.ree simply send us that address and we will do the rest.</span> </li>

                    <li style={{padding:'9px'}}>Your Location<br/><span className="italic text-sm">* If you have linkt.ee simply send us that address and we will do the rest.</span> </li>

                    <li style={{padding:'9px'}}>Link to your website<br/><span className="italic text-sm">* If your just starting out and have yet to get a website up, we can help you with that for a small addtional fee.</span> </li>
                   
                    </ol> 
                   
                    <h4>For Audio</h4>                    
                    <ol>
                    <li style={{padding:'9px'}}>Current Audio<br/>We will need your most recent '<span style={{textTransform:"uppercase"}}>'streaming platform quality audio"</span> Note: Soon we will put back into place our offering to sell your songs and keep 100% of your revenue. You must have a <a href="/joinPro" alt="join pro membership at the station tv">Pro Membership</a> for this feature. Once you email us with your information we will set you up a dropbox to upload your video to. Please contact if you have any questions about codecs. </li>

                    <li style={{padding:'9px'}}>Short Description of your project<br/><span className="italic text-sm">* Please no longer than one paragraph.</span> </li>
                    
                    <li style={{padding:'9px'}}>Your social media links<br/><span className="italic text-sm">* If you have linkt.ree simply send us that address and we will do the rest.</span> </li>

                    <li style={{padding:'9px'}}>Your Location<br/><span className="italic text-sm">* If you have linkt.ee simply send us that address and we will do the rest.</span> </li>

                    <li style={{padding:'9px'}}>Link to your website<br/><span className="italic text-sm">* If your just starting out and have yet to get a website up, we can help you with that for a small addtional fee.</span> </li>
                   
                    </ol>  
                          
            </div> 

            <div> 
          
          <p style={{marginTop:'5px', textAlign:'center', fontSize:'28px'}}> Send the above information to <a href="mailto:media@thestationtv.com">media@thestationtv.com</a><br/><span style={{fontSize:'20px'}}>For more information on our progress and publishing goals for 2024 please go to our <a href="./about" alt="the station tv about us and future plans">About Us</a></span></p>
          </div>


        </div>
</div>
  
      
 </div>
    
<Footer />
</div>
  
  )
}

export default JoinGeneral;
