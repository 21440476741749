import React, { useEffect, useState } from 'react';
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import {db} from '../../../../firebase';
import { useNavigate } from "react-router-dom";

import ReactSwitch from 'react-switch';
import TopNav from '../../core/TopNav';
import Footer from '../../core/Footer';

import "./admin.css";

const AdminDashboard = () => {

    // const artistNameRef = useRef();
    // const emailRef = useRef();
    // const passwordRef = useRef();
    // const passwordConfirmRef = useRef();
    // const { signupArtist } = useUserAuth();
  //  const [error, setError] = useState("");
  //  const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [liveStatus, setLiveStatus] = useState();
    const [isLive, setIsLive] = useState(false);

    const switchHandler = () => {
    
        setIsLive(!isLive);
    
          console.log("this is isActive ", isLive)
    
          const liveRef = doc(db, "Controlroom", "RxLcQRtIey9t2BA6x7MY");
    
          setDoc(liveRef, {
              MainStageLive: isLive
            })
    
             setLiveStatus(isLive);
            
            navigate("/mobile-admin-dashboard");
          
        };
    


  
    const fetchLive = async () => {
         
      await getDocs(collection(db, "Controlroom"))
          .then((querySnapshot)=>{               
              const newData = querySnapshot.docs
                  .map((doc) => ({...doc.data(), id:doc.id }));
              setLiveStatus(newData[0].MainStageLive);
          })
     
  }
  
  useEffect(()=>{
      fetchLive();
  }, [])

  
   
    return (
      <>
      <TopNav />
      <div className="middle-content extra-top">
      <div className="p-11 m-4">
        <h2>Mobile Admin</h2>
        <div>
        
          <div>
            <div className="bg-custom-background-admin p-3">
            <h3>Switch To Live</h3>
            <div className="text-center mt-3">
          
                <ReactSwitch
                    checked={liveStatus}
                    onChange={switchHandler}
                />
            </div>
            </div>
          </div>

          <div className="middle-content extra-top">
           <a href="/livestudiolobby" className="bg-transparent hover:bg-slate-500 text-slate-400 font-semibold hover:text-white py-2 px-4 border border-slate-400 hover:border-transparent rounded">Enter Live Studio</a>
          </div>
        </div>

    
        
            </div>
            </div>
        <Footer />
        </>
    )
}

export default AdminDashboard;