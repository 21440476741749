import React from 'react';
import MobileTopNav from "./MobileTopNav";
import MobileFooter from "./MobileFooter";
import { MobilePricing } from './MobilePricing';


const SubmitMedia = () => {
  return (
    <div>
      <MobileTopNav />
      <div className="middle-content">
        
        <p style={{fontSize:'20px', fontWeight:'bold', textTransform:'uppercase'}}>Submit Media</p></div>

      <p style={{fontSize:'18px'}}>Why Join The Station TV Artists &amp; Creators Alliance?</p>
      
      <p><span style={{color:'orangered'}}>Once you join you will be contacted by our promotional department.</span></p>
      
      <div>
       <MobilePricing />
      </div>
      

    
<MobileFooter />
</div>

  )
}

export default SubmitMedia