import React from "react";
import Topbar from '../core/TopNav';
import Footer from "../core/Footer";
//import ChatBox from "./chat/ChatBox";

import ReactPlayer from 'react-player'


const Live = () => {
  
    return (
      <div>
        <div>
      <Topbar />
      </div>
      <div>
         
       <div style={{display: 'flex', marginTop:'4em' }}>
       <div style={{width:"85%", height:"0", position:"relative", paddingBottom:"56.25%"}} >
        
       <div style={{position: 'relative', paddingBottom: '56.25%', height: 0}}><iframe src='https://player.viloud.tv/embed/live/c3e341aa83e343504383374eb019a1fe?autoplay=0&volume=1&controls=1&title=1&share=1&open_playlist=0' style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} frameborder='0' allow='autoplay' allowfullscreen></iframe></div>      
        
        
        
        {/* <iframe title="videoplayer" src="https://customer-klxke6nl22colsu4.cloudflarestream.com/ee564beffb9507e5a18fa23715f81c88/manifest/video.m3u8" style={{position:"absolute", width:"100%", height:"100%", overflow: "hidden"}} frameborder="0" allow="autoplay" allowfullscreen > </iframe>
         */}
        
        
        </div>
       {/* <div><iframe title="chat" src="https://chat.onestream.live/embed?token=dW5pdmVyc2FsLWNoYXQtMjA1MjEy" style={{height:'500px'}}  frameborder="0" allow="autoplay" allowfullscreen ></iframe></div> */}
        </div>


      </div>
      <Footer />
      
      </div>
    );


}

export default Live
