import React from 'react';

import { Button } from "react-bootstrap";

export const Pricing = () => {
    return (

          
      <div>        
    

    <div className="row p-2">
    <div className="col membership-gen">
        <div> <h4>General Membership</h4> </div>
        <div>FREE - Web/Mobile ONLY - 
        <span className="p-2"> <Button variant="secondary">         
            <a href="/joinGeneral" alt="Main streaming stage" className="text-white"><b>JOIN GENERAL</b></a>
            </Button></span>
        </div>
                
                <div>
                    
                        <ul>
                        <li className="py-3"><i className="fa fa-star" style={{color:'#0030ff', fontSize:"26px"}} /> &nbsp;  Submit Videos For Video Playlist<br/><span className="p-2">* Includes details page with all of your promotional information (ex. contact, social tag, location, website)<br/><span className="color-red"> ** In order to be streamed on our Roku Channel you will need a Pro Membership.</span></span></li>
                                               
                        <li className="py-3"><i className="fa fa-star" style={{color:'#0030ff', fontSize:"26px"}} /> &nbsp; Get a 50% discount on all of our Promotional Services<br/><span className="italic text-sm">* We provide a number of promotional services for our Artists &amp; Creators. Whether you need a refresh or something is missing in your marketing efforts, we can help!<br/><span className="p-2">* Websites, EPK's, Copyright Issues, song submissions, video ads, music videos</span></span> </li>
                       
                        </ul> 
                        <p style={{fontFamily:'verdana', color:'orangered', paddingTop:'25px'}}>LIMITED SPACE AVAILABLE!<br/>200 Artists or Creators Max! - This is NOT YoubeeTubee'<br/>Please read below for more info.</p>                           
                </div> 
    </div>
  
 
    <div className="col membership-pro">
        <div> <h4>Pro Membership</h4> </div>
        <div> $15.00/mo. - Web/Mobile + ROKU -  
           <span className="p-2">         
            <a href="/joinPro" alt="Main streaming stage" style={{backgroundColor:'#002fff', padding:'10px', borderRadius:'5px', color:'white'}}><b>JOIN PRO</b></a>
            </span>
          </div>
        <div>
                    
                    <ul>
                    <li className="py-3"><i className="fa fa-star" style={{color:'#0030ff', fontSize:"26px"}} /> &nbsp;  Submit Videos For Video Playlist<br/><span className="p-2">* Includes details page with all of your promotional information (ex. contact, social tag, location, website)<br/> ** Submit your most recent video with free new submissions for the year.</span></li>
                    <li className="py-3"><i className="fa fa-star" style={{color:'#0030ff', fontSize:"26px"}} /> &nbsp; Custom QR-Code<br/><span className="italic text-sm">* We will create (if you don't have one) and display a custom QR-Code Tip Jar that will send your fan anywhere you want them to go!<br/><span className="p-2">* Spotify, Venmo, Cashapp, Patreon, Website</span></span> </li>

                    <li className="py-3"><i className="fa fa-star" style={{color:'#0030ff', fontSize:"26px"}} /> &nbsp; Participate in Live Interview Shows<br/><span className="italic text-sm">* We are running or planning to run a number of Interview Shows with Live Chat so you can talk with your fan base. People love talking to the people the love to watch. We are even set up to bridge a viewer's video into the show.</span>
                        </li>

                        <li className="py-3"><i className="fa fa-star" style={{color:'#0030ff', fontSize:"26px"}} /> &nbsp; Live Stream Your Shows or Events<br/><span style={{color:'#108fe9', textTransform:'uppercase'}}>Want to be on ROKU?</span><br/><span className="italic text-sm">* If you are local (southern california area) we will come to your next gig or event with a remote kit and stream you to The Station TV. <br/>* If you're not local we can show you how to send us a feed with your cellphone or home studio. Some shows we can even provide a PayWall. This can be discussed during pre-production meetings.</span>
                        </li>
                                           
                    <li className="py-3"><i className="fa fa-star" style={{color:'#0030ff', fontSize:"26px"}} /> &nbsp;  Submit Your latest Song<br/><span className="p-2">* New for 2024 - Let's build a library! Send us your latest .mp3 and we'll add it to our library so when radio stations, venues, labels or someone just looking to buy a song logs in, they will be able to download your song for air play. ** We can't gaurentee they'll want your song but we will do our best to make sure they hear it!</span> <span style={{color:'orangered'}}>All songs submitted must be Radio Quality!</span></li> 
                    {/* <li className="py-3"><i className="fa fa-star" style={{color:'lightgreen', fontSize:"26px"}} /> &nbsp;  Submit Show Ideas<br/><span className="p-2">* Have a new show idea or would like us to produce a series idea. Pitch what you have to us and we will see if we can make it happen!</span></li>                         */}
                    
                        <li className="py-3"><i className="fa fa-star" style={{color:'#0030ff', fontSize:"26px"}} /> &nbsp; Make Money With Your Music<br/><span className="italic text-sm">*** We will actively keep an ear out and contact Radio Stations and Publishers from the list that we are putting together this year. You will also be able to attend private instructional sessions to learn more about the music industry and what you can do to promote yourself.<br/><span className="p-2">* Some sessions require an extra fee. TBD</span></span> </li>
                       
                        <li className="py-3"><i className="fa fa-star" style={{color:'#0030ff', fontSize:"26px"}} /> &nbsp;  Electronic Press Kit<br/><span className="p-2">* Send us your EPK and when radio stations, venues, labels or someone just looking to buy a song logs in, they will be able to download your press kit!</span></li> 

                        
                        
                        <li className="py-3"><i className="fa fa-star" style={{color:'#0030ff', fontSize:"26px"}} /> &nbsp; Come To Our Artists &amp; Creators Retreat at a HUGE discounted rate!<br/><span className="italic text-sm">* Since COVID we have been hard at work building our little Artists & Creators Retreat in Joshua Tree, Ca. It is a place to stay, record, create or chill in a setting full of desert vibe and beautiful scenery.</span>
                        </li>

                        <li className="py-3"><i className="fa fa-star" style={{color:'#0030ff', fontSize:"26px"}} /> &nbsp; Get a 50% discount on all of our Promotional Services<br/><span className="italic text-sm">* We provide a number of promotional services for our Artists &amp; Creators. Whether you need a refresh or something is missing in your marketing efforts, we can help!<br/><span className="p-2">* Websites, EPK's, Copyright Issues, song submissions, video ads, music videos</span></span> </li>
                    </ul> 
                    <p style={{fontFamily:'verdana', color:'orangered'}}>LIMITED SPACE AVAILABLE!<br/>200 Max Members!<br/>Please read below for more info.</p>                           
            </div> 
            </div>
  </div>
  
  <div className="more-membership">
       <h2>We Aren't Like Everyone Else - 200 Members Max!</h2>  
      <p>Our main goal is to provide a promotional tool for independent artists and creators as well as help promote them/you by streaming shows, video, audio and giving you the ability to monetize everything and keep 100% of your profits. What we DON'T want is to be like the facebooks, youtubes, and twitchs of the world that allow everyone and anyone to play in their sandbox. This creates an atmosphere which I like to call the "Virtual Black Hole" (deep voice with echo). Therefore we will be limiting how many Artists, Creators &amp; Shows that we stream on The Station TV so everyone gets equal exposure!</p>
      <p style={{padding:'9px'}}>A few more requirements...
      <ul style={{padding:'25px'}}>
        <li style={{listStyleType:'initial'}}>You must be actively working on your art and serious about your promotions.</li>
        <li style={{listStyleType:'initial'}}>You must be actively sharing posts from other members and The Station TV.</li>
        <li style={{listStyleType:'initial'}}>Please no videos putting down anyone elses political or religious beliefs. We try and steer clear of controversy in a world of hate. Be nice to your fellow humans...  pretty simple eh?</li>
        <li style={{listStyleType:'initial'}}>While we don't care what genre you represent, we do care that you represent it well.</li>
        <li style={{listStyleType:'initial'}}>We are trying to build a community that pushes others to acheive as well. Please be nice and helpful to your fellow artists. Are you noticing a trend here?</li>
      </ul>                 
      </p> 
          Note: All artists, creators, filmmakers and educators that are streamed on The Station TV have given us permission to distribute their content. The Station TV at no time claim rights to any work, art, or any content by artists, creators, filmmakers or educators. All rights reserved on works of art created by The Station TV's production team. Please read the <a href="/terms" style={{color:'#fff'}}>Terms Of Service</a> for more information.</div>

</div>

)
}

