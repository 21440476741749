import React, { useRef } from "react";
import { Form, Button, Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../../firebase";

import Topbar from './TopNav';
import Footer from './Footer';


const ForgotPassword = () => {
  window.scrollTo(0, 0)

  const emailRef = useRef();
  const navigate = useNavigate();



async function handleSubmit(e) {
  e.preventDefault()

  const emailaddress = emailRef.current.value;
  return sendPasswordResetEmail(auth, emailaddress).then((a) => {
        alert("Password reset email sent")
        navigate("/")
      })
}

  return (
    <>
    <Topbar />
		<div className="middle-content extra-top">
		<div className="p-3">
		<div style={{textAlign:'center', fontSize:'22px', textTransform:'uppercase', padding:'25px'}}>Request Password Reset</div>
       		<div>			
           <Card style={{padding:'25px', width:'55%', margin:'auto', backgroundColor:'#222'}}>
       <Card.Header>
						<Card.Title style={{color:'white'}}>Your password doesn't have to be that difficult but you should never use a "financial level" password for these type of accounts. We are here to protect you and our artists / creators. Your email is never sold and we seriously don't care about your personal data.<br/><span style={{color:'red'}}>** make sure to check your spam folder for your reset link.</span></Card.Title>
					</Card.Header>
       <Card.Body>
         <div>
        
          <Form onSubmit={handleSubmit} className="p-11 flex-col">
            <Form.Group id="email" className="mb-3">
              <Form.Label style={{color:'white'}}>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required className="bg-white text-black flex justify-end p-2 w-full" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Reset Password
            </Button>
          </Form>
          </div>
        </Card.Body>
        <Card.Footer>
        <div style={{color:'white'}}>
        Need an account? <Link to="/signup">Sign Up</Link>
      </div> 
        </Card.Footer>
      </Card>
      
    </div>
   </div>
   </div>
   <Footer />
   </>
  )
}

export default ForgotPassword;
