import React from 'react';

import TopNav from "./TopNav";
import Footer from "./Footer";

const Privacy = () => {
    window.scrollTo(0,0);

    return (
        <div>
        <TopNav />
       
        <div className="middle-content extra-top">
                <h3>Introduction</h3><span className="text-sm italic">Last updated December 29, 2023</span><br/><br/>
Thank you for choosing to be part of our community at The Station TV. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at media@thestationtv.com. <br/>
When you visit our website www.thestationtv.com and other platforms that provide The Station TV's content, and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy policy, we describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our App and our services.<br/>
This privacy policy applies to all information collected through our OTT The Station TV at www.thestationtv.com and other platforms that provide The Station TV's content, as well as any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the “Services“). 
Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us. <br/><br/>
1. WHAT INFORMATION DO WE COLLECT? <br/>
Personal information you disclose to us.<br/>
We do not collect information from you and try to do everything with QR Codes where payments or any type of monies are transacted. In order to keep things simple the only information we collect from the user is an email address and name which is strictly used to provide a space for the user to return. All ticket, merch, donations or any other type of financial transaction is done with the user preferred payment method. Any other information that may be picked up from the users cookies on their browser (if applicable) is not coming from us.Please make sure to adhere to browser protection practices.<br/>
All information collected from Artists or Show Creators give us permission ahead of time to provide their information to our viewers. All assets that are provided on The Station TV for viewing are under copyright and publishing laws and protected by the PROs that the asset was licensed with. 
<br/><br/>
2. HOW DO WE USE YOUR INFORMATION? <br/>
If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.
We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent. The information collected when you sign up for an account is strictly used for our business purposes described below only and is never sold or used for illegal use. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below. <br/><br/>
We use the information we collect or receive: <br/>
To send you marketing and promotional communications. We and/or our third-party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt-out of our marketing emails at any time (see the “WHAT ARE YOUR PRIVACY RIGHTS” section below).     
To send administrative information to you. We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies. <br/><br/>

Request Feedback: <br/> We may use your information to request feedback and to contact you about your use of our services. To protect our Services. We may use your information as part of our efforts to keep our services safe and secure (for example, for fraud monitoring and prevention). <br/>
To enforce our terms, conditions and policies for Business Purposes, Legal Reasons and Contractual.
To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond. <br/> 
To manage user accounts. We may use your information for the purposes of managing your account and keeping it in working order. 
To deliver services to the user. We may use your information to provide you with the requested service. 
To respond to user inquiries/offer support to users.  We may use your information to respond to your inquiries and solve any potential issues you might have with the use of our Services. <br/> <br/>
For other Business Purposes:<br/>We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Services, products, marketing and your experience. We may use and store this information in aggregated and anonymized form so that it is not associated with individual end users and does not include personal information. We will not use identifiable personal information without your consent. <br/><br/>
3. WILL YOUR INFORMATION BE SHARED WITH ANYONE? <br/>
Quick Answer: NO!<br/>
We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations. We may process or share data based on the following legal basis:
These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.
<br/><br/>
Consent:<br/>We may process your data if you have given us specific consent to use your personal information in a specific purpose. <br/><br/>
Legitimate Interests:<br/>We may process your data when it is reasonably necessary to achieve our legitimate business interests.<br/><br/>
Performance of a Contract:<br/>Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.<br/><br/>
Legal Obligations:<br/>We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements). <br/>
Vital Interests:<br/>We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.<br/>
More specifically, we may need to process your data or share your personal information in the following situations: 
<br/>
Vendors, Consultants and Other Third-Party Service Providers. We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include:  data analysis, email delivery, hosting services and marketing efforts. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes. 
Business Transfers:<br/>We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company. You will be notified prior to any company changes.<br/><br/>
Third-Party Advertisers:<br/>We may use third-party advertising companies to serve ads when you visit the site. These companies may use information about your visits to our Website(s) and other websites that are contained in web cookies and other tracking technologies in order to provide advertisements about goods and services of interest to you.
Affiliates.  We may share your information with our business partners to offer you certain products, services or promotions. <br/><br/>
4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?<br/>
We may use cookies and other tracking technologies to collect and store your information. 
We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy. <br/><br/>
5. DO WE USE GOOGLE MAPS? <br/>
In Short: NO<br/><br/>
6. HOW LONG DO WE KEEP YOUR INFORMATION? <br/>
We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law. 
We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than 90 days past the termination of the user's account. When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.<br/><br/>
7. HOW DO WE KEEP YOUR INFORMATION SAFE? <br/> 
We aim to protect your personal information through a system of organizational and technical security measures.
We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our site is at your own risk. You should only access the services within a secure environment.<br/><br/>
8. DO WE COLLECT INFORMATION FROM MINORS? <br/>
We do not knowingly collect data from or market to children under 18 years of age. 
We do not knowingly solicit data from or market to children under 18 years of age. By using the site, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at media@thestationtv.com. <br/><br/>
9. WHAT ARE YOUR PRIVACY RIGHTS? <br/>
You may review, change, or terminate your account at any time. If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here:
 http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm<br/><br/>
Account Information:<br/>
If you would at any time like to review or change the information in your account or terminate your account, you can:<br/>
Contact us using the contact information provided.<br/>
Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.<br/><br/>
Cookies and similar technologies:<br/>Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. To opt-out of interest-based advertising by advertisers on our Services visit http://www.aboutads.info/choices/ . <br/><br/>
Opting out of email marketing:<br/>You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list however, we will still need to send you service-related emails that are necessary for the administration and use of your account. To otherwise opt-out, you may:<br/>
Contact us using the contact information provided.<br/><br/>
CONTROLS FOR DO-NOT-TRACK FEATURES:<br/>
Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy policy.<br/><br/>
11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?: <br/>
Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information. California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below. <br/>
If you are under 18 years of age, reside in California, and have a registered account with the Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from our systems.<br/><br/>
12. DO WE MAKE UPDATES TO THIS POLICY?<br/>
Yes, we will update this policy as necessary to stay compliant with relevant laws. We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.<br/><br/>
13. HOW CAN YOU CONTACT US ABOUT THIS POLICY? <br/>
<a href="mailto:media@thestationtv.com" alt="The Station TV">Email</a>
<div className="bottom-padding"></div>

   </div>





    
<Footer />
</div>
    )

}

export default Privacy;