import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import jess from '../../../../assets/images/jess.jpg';

// Components
import { AudiocastPlayer } from "./AudiocastPlayer";


const Audiocast = () => {
  return (
    <div>
    <TopNav />
    
    <div className="middle-content extra-top container">
    <div><h3>The Station Audiocast</h3></div>

    <div className="row"><AudiocastPlayer /></div>

    <div className="row">
    <div style={{textAlign:'center', fontSize:'22px', padding:'15px'}}>Artist Spotlight</div> 
    <div>
    
    <p style={{paddingLeft:'55px'}}>We are currently open for submissions for our radio portion of The Station TV.
   <strong> Please submit the following information:</strong>
<ul>
<li style={{marginLeft:'25px', listStyleType: 'circle'}}>Send latest MP3</li>
<li style={{marginLeft:'25px', listStyleType: 'circle'}}>Album cover image or some sort of image of artists (thumbnail size)</li>
<li style={{marginLeft:'25px', listStyleType: 'circle'}}>Artists Name</li>
<li style={{marginLeft:'25px', listStyleType: 'circle'}}>Location of Artist: city, state, country</li>
<li style={{marginLeft:'25px', listStyleType: 'circle'}}>Social Tags (ie. yours is @mycoolband)</li>
<li style={{marginLeft:'25px', listStyleType: 'circle'}}>Website (if you have one)</li>
<li style={{marginLeft:'25px', listStyleType: 'circle'}}>QR Code (that takes your fans to your spotify or whereever; or if you don't have one we will make one for you.)</li>
</ul>
<br/>
* Note: We will be needing you to fill out a simple permission form for Roku that simply states you agree to let us play (and only play, not distribute for sale or own your rights or anything devious) your song on our Station.

<p style={{textAlign: 'center', padding:'25px'}}>
<a href="mailto:media@thestationtv.com" alt="Main streaming stage" style={{backgroundColor:'#002fff', padding:'10px', borderRadius:'5px', color:'white'}}> SUBMIT MEDIA</a>
</p>              
  
    </p>    
    
    </div>

    {/* <div className="col" style={{marginTop:'75px', padding:'25px'}}><img src={jess} width="450" alt="The Station TV Radio or Audiocast" /></div> */}
    </div>

<div style={{textAlign:'center'}}>If you would like your song to be played in our morning and afternoon drive to work time slot please email us at: <a href="mailto:media@thestationtv.com">Media Submissions</a>.</div>
    
    
    </div>
    <Footer />
</div>
  );
}

export default Audiocast;