import React from 'react';

import TopNav from "./TopNav";
import Footer from "./Footer";


const Terms = () => {
    window.scrollTo(0,0);

    return (
        
        <div>
        <TopNav />
         
        <div className="middle-content extra-top">
                <h3>TERMS &amp; CONDITIONS</h3>
The provisions below constitute an agreement between the user The Station TV:<br/><br/>
Web: https://www.thestationtv.com<br/>
Roku: The Station TV<br/>
Mobile: https://www.thestationtv.com
<br/><br/>
The Station TV website and other devices (Roku) is comprised of various pages, productions and events operated by The Station TV and other partners.<br/>
The Station TV website and subsequent applications on other platforms are offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the “Terms”). Your use of https://thestationtv.com/ and other platforms that provide The Station TV's content constitutes your agreement to all such Terms. Please read these terms carefully and keep a copy of them for your reference.<br/><br/>

A SPECIAL NOTE:<br/>
The Station TV is an online TV Station (OTT) designed for your entertainment. The artists, show owners and anyone providing content to The Station TV keep full ownership of content. Show owner takes all responsibility for all remarks during a live show and while we coach what words to avoid and try to ban all offensive comments, The Station TV and Lori Bannon founder and owner hold no responsibility for live or video on demand content provided.<br/><br/>
 
Ticket Purchase:<br/>
Our entertainers work hard on their craft. We provide a QR Code as a Tip Jar for these entertainers to generate income from their shows and other assets they provide to The Station TV. Please see fit to tip your artist and support them during their shows. These tips go directly to the artists and The Station TV has no part in the transaction.<br/><br/>
Refund Policy:<br/>
All Pro Members can quit at anytime. You must contact us to take you of the $12.00/month subscription plan.<br/>
All tips are final. <br/>
No unlawful or prohibited use/Intellectual Property:<br/>
All content included as part of the https://thestationtv.com/ and other platforms that provide The Station TV's content reading, such as text, graphics, logos, images, as well as the compilation thereof, any software used on the App, any original shows, episodes or series produced by The Station TV, excluding assets provided by Show Owners and Artists, are the property of Lori Bannon owner and operator of The Station TV and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.<br/><br/>
International Users:<br/>
The Site is controlled, operated and administered by The Station TV and associates from our offices within the USA. If you access the App from a location outside the USA, you are responsible for compliance with all United States laws. You agree that you will not use The Station TV content accessed through https://thestationtv.com in any manner prohibited by any applicable laws, restrictions or regulations.<br/><br/>
Indemnification:<br/>
You agree to indemnify, defend and hold harmless Lori Bannon and all The Station TV content holders, its officers, directors, employees, agents and third parties (including venue host, caterer, and class instructors), for any losses, costs, liabilities and expenses (including reasonable attorneys’ fees) relating to or arising out of your use of or inability to use the App or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. Bannon Digital Media reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with The Station TV in asserting any available defenses.<br/><br/>
Changes to Terms:<br/>
The Station TV reserves the right, in its sole discretion, to change the Terms under which https://thestationtv.com and other platforms that provide The Station TV's content is offered. The most current version of the Terms will supersede all previous versions. Bannon Digital Media encourages you to periodically review the Terms to stay informed of our updates.
<br/><br/>Effective as of October 17, 2019


   </div>





    
<Footer />
</div>

    )

}

export default Terms;