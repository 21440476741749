import React from 'react';

import TopNav from "./TopNav";
import Footer from "./Footer";


const JoinPro = () => {
 

  return (
<div>
      <TopNav />
      <div className="middle-content extra-top container">
  
  
        <h3 style={{marginTop:'25px'}}>Signup For A Pro Membership</h3>
    
        <div style={{marginTop:'25px'}}>Once you sign up you will be contacted by us within 24/48 hrs. Please email us at <a href="mailto:media@thestationtv.com" alt="the station tv email">media@thestationtv.com</a> to cancel or if you have any questions.</div>

        <div style={{marginTop:'25px', border:'1px solid orange', padding:'55px'}}>
        <h5>Disclosures and Legal Requirements</h5>
        <ul>
          <li style={{padding:'15px', color:'orange'}}>Before signing up for a Pro Membership please note that if you are under contract with anyone you must have permission.</li>

          <li style={{padding:'15px'}}>We do not pay for your video or audio that you submit. However, we will pay for marketing and promotions when applicable.</li>

          <li style={{padding:'15px'}}>We are not interested and will not attempt to monetize your music (recording) on any platform.</li>

          <li style={{padding:'15px'}}>We are a very small operation so there is no post date for your assets (audio/video) however Pro Members have precedence.</li>

          <li style={{padding:'15px'}}>We reserve the right to refuse any video that may not suit our community or Roku's requirements.</li>
          
          </ul>
        </div>
    
    <div style={{textAlign:'center', padding:'25px'}}>
    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
  <input type="hidden" name="cmd" value="_s-xclick" />
  <input type="hidden" name="hosted_button_id" value="9DDL8NC3WNJY6" />
  <input type="hidden" name="currency_code" value="USD" />
  <input type="image" src="https://res.cloudinary.com/dhjmkhdkg/image/upload/v1715976170/2024/monthly_sub_mfj0ey.jpg" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Subscribe" />
</form>
</div>

<div>
You can cancel at anytime. While you're waiting please start to gather the information below. <span className={{color:'orange'}}> We do everything we can to stop copyright infridgements but it is mandatory that you cover your</span>


          <div>
          <p style={{paddingTop:'25px', fontSize:'18px'}}>What We Need From You:</p>
                    
                    <ol>
                    <li className="py-3">Current Video<br/><span className="p-2">* If you don't have a video please let us know and if you are local we will setup a date to come out and record you live.<br/><span className="color-red"> ** The venue you are playing with must be ok with us coming in and streaming. We will work out the details with the venue.</span></span></li>
                    
                    <li className="py-3">Most Recent Audio<br/><span className="p-2"> Audiocast is up and running again. We will add your audio for our morning and afternoon commute shows as well as place you on the daily playlist. Soon you will be able to sell your songs online and keep 100% of profits.</span> * Please see our <a href="/about">about </a> page for future plans and our 2024 roadmap. </li>

                    <li className="py-3">Short Description of your project<br/><span className="italic text-sm">* Please no longer than one paragraph.</span> </li>
                   
                    <li className="py-3">Your social media links<br/><span className="italic text-sm">* If you have linkt.ree simply send us that address and we will do the rest.</span> </li>

                    <li className="py-3">Your Location<br/><span className="italic text-sm">* If you have linkt.ee simply send us that address and we will do the rest.</span> </li>

                    <li className="py-3">Link to your website<br/><span className="italic text-sm">* If your just starting out and have yet to get a website up, we can help you with that for a small addtional fee.</span> </li>
                   
                    </ol> 
                  
            </div> 


</div>
 </div>
    
<Footer />
</div>
  
  )
}

export default JoinPro;
