import React from 'react';
import TopNav from "./MobileTopNav";
import Footer from "./MobileFooter";

const Thankyou = () => {
  return (
    <div style={{paddingBottom:'75px'}}>
      <TopNav />
      <div className="middle-content">
        
        <h1>Welcome To The Station TV Pro Membership</h1></div>

     
      
      <p>Thanks for joining. We will recieve a notification and will be contacting you within 24/48 hours. In the meantime please start collecting the following information.</p>
     
      <div>
          
                    
                    <ol>
                    <li className="py-3">Current Video<br/><span className="p-2">* If you don't have a video please let us know and if you are local we will setup a date to come out and record you live.<br/><span className="color-red"> ** The venue you are playing with must be ok with us coming in and streaming. We will work out the details with the venue.</span></span></li>
                    
                    <li className="py-3">Most recent Audio<br/><span className="p-2"> ** We plan to actively shop your tune to local radio stations, publishers and labels. Please view our <a href="/about" alt="the station tv project management road map">Project Roadmap</a> for more details.</span><span className="color-red">* If you are a creator please send us your most recent podcast and we will add as we go.</span> </li>

                    <li className="py-3">List of PRO's you belong to if any.<br/><span className="italic text-sm">* If you have yet to publish your works we can help with that.</span> </li>

                    <li className="py-3">Short Description of your project<br/><span className="italic text-sm">* Please no longer than one paragraph.</span> </li>
                   
                    <li className="py-3">Your social media links<br/><span className="italic text-sm">* If you have linkt.ree simply send us that address and we will do the rest.</span> </li>

                    <li className="py-3">Your Location<br/><span className="italic text-sm">* If you have linkt.ee simply send us that address and we will do the rest.</span> </li>

                    <li className="py-3">Link to your website<br/><span className="italic text-sm">* If your just starting out and have yet to get a website up, we can help you with that for a small addtional fee.</span> </li>
                   
                    </ol> 
                    <p style={{fontFamily:'verdana', color:'orangered', paddingTop:'25px'}}>LIMITED SPACE AVAILABLE!<br/>200 Artists or Creators Max! - This is NOT YoubeeTubee'<br/>Please read below for more info.</p>                           
            </div> 

            <div>If you have any questions please contact us at <a href="mailto:media@thestationtv.com" alt="the station tv email address" >media@thestationtv.com</a> or you can text me direct at 714.597.2178. Please do not give out this number. This mobile number is for members only.</div>


    
<Footer />
</div>
  )
}

export default Thankyou