import { Container, Row, Col } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";

import MediaQuery from 'react-responsive';

import "./App.css";
import Home from "./components/web/core/Home";
import Login from "./components/web/core/Login";
import Signup from "./components/web/core/Signup";
import MainStage from "./components/web/core/MainStage";
import Details from "./components/web/core/Details";

import Audiocast from "./components/web/core/audiocast/Audiocast";
import AudioGuide from "./components/web/core/audiocast/AudioGuide";

import SubmitMedia from "./components/web/core/SubmitMedia";
import { Pricing } from "./components/web/core/Pricing";
import Services from "./components/web/core/Services";
import { About } from "./components/web/core/About";
import Terms from "./components/web/core/Terms";
import Privacy from "./components/web/core/Privacy";

import ProtectedRoute from "./components/web/core/ProtectedRoute";
import Live from "./components/web/private/Live";
import JoinGeneral from "./components/web/core/JoinGeneral";
import JoinPro from "./components/web/core/JoinPro";
import AdminDashboard from "./components/web/private/admin-dashboard/AdminDashboard";
import MemberDashboard from "./components/web/private/member-dashboard/MemberDashboard";
import ForgotPassword from "./components/web/core/ForgotPassword";
import Thankyou from "./components/web/private/Thankyou";


// mobile pages

import MobileHome from "./components/mobile/MobileHome";
import MobileLogin from "./components/mobile/MobileLogin";
import MobileSignup from "./components/mobile/MobileSignup";
import MobileJoinGeneral from "./components/mobile/MobileJoinGeneral";
import MobileJoinPro from "./components/mobile/MobileJoinPro";
import MobileAbout from "./components/mobile/MobileAbout";
import MobileServices from "./components/mobile/MobileServices";
import MobileGuide from "./components/mobile/MobileGuide";
import MobileAudiocast from "./components/mobile/audiocast/Audiocast";
import MobileSubmitMedia from "./components/mobile/MobileSubmitMedia";
import MobileMainStage from "./components/mobile/MobileMainStage";
import MobileLive from "./components/mobile/MobileLive";
import MobileThankyou from "./components/mobile/MobileThankyou";
import MobileMemberDashboard from "./components/web/private/member-dashboard/MobileMemberDashboard";
import MobileAdminDashboard from "./components/web/private/admin-dashboard/MobileAdminDashboard";
import MobileForgotPassword from "./components/mobile/MobileForgotPassword";




import { UserAuthContextProvider } from "./context/UserAuthContext";

function App() {

  return (
    <>
    <MediaQuery maxDeviceWidth={1023}>
    <Container>
      <Row>
        <Col>
          <UserAuthContextProvider>
           <Routes>                           
              <Route path="/" element={<MobileHome />} />
              <Route path="/mobile-login" element={<MobileLogin />} />
              <Route path="/mobile-signup" element={<MobileSignup />} />
              <Route path="/mobile-join-general" element={<MobileJoinGeneral />} />
              <Route path="/mobile-join-pro" element={<MobileJoinPro />} />
              <Route path="/mobile-about" element={<MobileAbout />} />
              <Route path="/mobile-services" element={<MobileServices />} />
              <Route path="/mobile-guide" element={<MobileGuide />} />
              <Route path="/mobile-audiocast" element={<MobileAudiocast />} />
              <Route path="/mobile-submit-media" element={<MobileSubmitMedia />} />
              <Route path="/mobile-main-stage" element={<MobileMainStage />} />
              <Route path="/mobile-live" element={<MobileLive />} />
              <Route path="/thankyou" element={<MobileThankyou />} />
              <Route path="/forgot-password" element={<MobileForgotPassword />} /> 
              <Route
                path="/mobile-member-dashboard"
                element={
                  <ProtectedRoute>
                    <MobileMemberDashboard />
                  </ProtectedRoute>
                }
              /> 
               <Route
                path="/mobile-admin-dashboard"
                element={
                  <ProtectedRoute>
                    <MobileAdminDashboard />
                  </ProtectedRoute>
                }
                />            
            </Routes>
          </UserAuthContextProvider>
        </Col>
      </Row>
    </Container>

    </MediaQuery>

    <MediaQuery minDeviceWidth={1024}>
    <Container>
      <Row>
        <Col>
          <UserAuthContextProvider>
            <Routes>
              <Route
                path="/admin-dashboard"
                element={
                  <ProtectedRoute>
                    <AdminDashboard />
                  </ProtectedRoute>
                }
              />
               <Route
                path="/member-dashboard"
                element={
                  <ProtectedRoute>
                    <MemberDashboard />
                  </ProtectedRoute>
                }
              />
             
              <Route
                path="/live"
                element={
                  <ProtectedRoute>
                    <Live />
                  </ProtectedRoute>
                }
              />
               <Route
                path="/thankyou"
                element={
                  <ProtectedRoute>
                    <Thankyou />
                  </ProtectedRoute>
                }
              />
                           
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/joinGeneral" element={<JoinGeneral/>} />
              <Route path="/joinPro" element={<JoinPro/>} />
              <Route path="/mainstage" element={<MainStage/>} />
              <Route path="/audiocast" element={<Audiocast/>} />
              <Route path="/tv-guide" element={<AudioGuide/>} />
              <Route path="/details/:videoId" element={<Details/>} />
              <Route path="/submit-media" element={<SubmitMedia/>} />
						  <Route path="/pricing" element={<Pricing/>} />
						  <Route path="/services" element={<Services/>} />
						  <Route path="/about" element={<About/>} />
              <Route path="/terms" element={<Terms/> } />
						<Route path="/privacy" element={<Privacy/> } />
            </Routes>
          </UserAuthContextProvider>
        </Col>
      </Row>
    </Container>
    </MediaQuery>
    </>
  );
}

export default App;
