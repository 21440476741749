import React, { useState, useEffect } from 'react';

import TopNav from "../TopNav";
import Footer from "../Footer";
import "./audiocast.css";

const AudioGuide = () => {
  window.scrollTo(0, 0)

  const [audioList, setAudioList] = useState([""]);
  //const [audioIndex, setAudioIndex] = useState(0);
 
  
 const getTrackData = () => {   
   
  fetch('audiocast.json',{
    headers : { 
      'Content-Type': 'application/json',
      'Accept': 'application/json'
     }
  }).then(function(response){
      return response.json();
    })
    .then(function(myJson) {
      setAudioList(myJson);     
    });
}

useEffect(()=>{
  getTrackData()
},[])

console.log("this is audio list ", audioList)
        
     return (
        <div>
            
        <TopNav />
      <div className="extra-top" style={{marginTop:'55px'}}>
      <div className="row-container">
          <div><h2 style={{textAlign:'center'}}>The Station TV Guide - Audiocast</h2></div>
         
        <div>
        {audioList.map((audio) => ( 
            <div className="row">
          <div><hr size="2"></hr></div>     
        <div className="col">           
          <img src={audio.thumbnail} alt="slider for artists" className="" height="152px" />
        </div>
        <div className="col">
          <p className="">{audio.artistName}</p>
           <p>BIO: {audio.shortDescription}</p>
        </div>

            <div className="col">Song Name: {audio.songName}<br/>Location: {audio.location}<br/>Social Tag: {audio.socialTag}</div>
            <div className="col">
          <img src={audio.qrcodeImage} alt="slider for artists" className="" height="152px" />
        </div>
        
        </div>
          ))}             
        </div>
                  
                             

         
      </div>
     
      </div>
      <Footer />
      </div>
    );
}

export default AudioGuide;

