import React from 'react';
//import Newsletter from './Newsletter';
//import qrcode from '../../../assets/qr_codes/donate_qrcode.jpg';

const Footer = () => {
    return (

<div className="container-fluid top-border">
  <div className="row">
     
      <div className="col">
          <div style={{color:'grey', fontSize:'16px', fontWeight:'bold', textTransform:'uppercase'}}>Corporate</div>
          <ul className="list-reset leading-normal">
          <li className="hover:text-rust text-grey-darker"><a href="/terms" alt="streaming the station tv" style={{color:'gray', fontSize:'13px'}}>Terms Of Use</a></li>
          <li className="hover:text-rust text-grey-darker"><a href="/privacy" alt="streaming the station tv" style={{color:'gray', fontSize:'13px'}}>Privacy Policy</a></li>               
            <li className="hover:text-rust text-grey-darker"><a href="/about" alt="streaming the station tv" style={{color:'gray', fontSize:'13px'}}>About Us</a></li>
            {/* <li className="hover:text-rust text-grey-darker"><a href="/donations" alt="streaming the station tv" style={{color:'gray', fontSize:'13px'}}>Become A Sponsor</a></li> */}
            <li className="hover:text-blue text-grey-darker"><a href="mailto:media@thestationtv.com" style={{color:'gray', fontSize:'13px'}}>Contact Us</a></li>
          </ul>
      </div>

      <div className="col">
          <div style={{color:'grey', fontSize:'16px', fontWeight:'bold', textTransform:'uppercase'}}>Services</div>
          <ul className="list-reset leading-normal">
          <li className="hover:text-blue-light text-gray-400">
                <a href="/tv-guide" style={{color:'gray', fontSize:'13px'}}> Playlist Guide</a>
                </li>
                <li className="hover:text-blue-light text-gray-400">
                <a href="/joinGeneral" style={{color:'gray', fontSize:'13px'}}> Submit For Free</a>
                </li> 

                <li className="hover:text-blue-light text-gray-400">
                <a href="/joinPro" style={{color:'gray', fontSize:'13px'}}> Join Pro</a>
                </li>             
            
            <li className="hover:text-blue-light text-gray-400">
                <a href="/services" style={{color:'gray', fontSize:'13px'}}> Promotional Services</a>
                </li>
           
          </ul>
      </div>

  <div className="col">
     <div style={{color:'grey', fontSize:'16px', fontWeight:'bold', textTransform:'uppercase'}}>Social Media</div>
      <ul className="list-reset leading-normal">                 
        <li className="hover:text-blue-light text-gray-400"><a target="_blank" rel="noopener noreferrer" href="https://facebook.com/thestation.tv" alt="the station tv facebook" style={{color:'gray', fontSize:'13px'}}>Facebook</a></li>
        <li className="hover:text-blue-light text-gray-400"><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/thestation.tv/" alt="the station tv instagram" style={{color:'gray', fontSize:'13px'}}>Instagram</a></li>
        <li className="hover:text-blue-light text-gray-400"><a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@thestation.tv" alt="the station tv facebook" style={{color:'gray', fontSize:'13px'}}>TikTok</a></li>
        <li className="hover:text-blue-light text-gray-400"><a target="_blank" rel="noopener noreferrer" href="https://wa.me/17145972178" alt="the station tv facebook" style={{color:'gray', fontSize:'13px'}}>Whatsapp</a></li>
        
      </ul>

  </div>
 
  <div className="col">
    {/* <Newsletter /> */}
     <div width="100%" data-mooform-id="44e05d71-2e95-4301-8cc8-df5cc1bc0036" ></div>

    
   {/* </div>

     
   <div className="col text-center">
              
   <div className="ml-40"><img src={qrcode} width="50%" alt="donate to the station tv" /></div> */}
        </div>  
       
        </div>

        <div style={{marginTop:'45px'}}> 
          <div>
                <h6 style={{color:'grey', fontSize:'16px'}}>The Station TV - Promotional Streaming Platform For Our Clients &#169; 2019 - {new Date().getFullYear()}</h6>
                <div style={{color:'grey', fontSize:'16px'}}>The Station TV is our distribution platform for films we make, art we create, shows we produce, guest interviews, artist promotions and just about anything I feel like waking up and doing from my private artists retreat in Joshua Tree. The Station TV hold no rights to any of the works provided by artists and creators that are streamed and are not responsible for any content displayed. All artists and creators have given The Station TV permission to display their works. If you see something that does not meet our <a href="/terms"> term of use</a> or policies, please let us know.</div>
                <div style={{color:'grey', fontSize:'16px', marginBottom:'25px'}}>
                    All Rights Reserved The Station TV - Artists, Events and Show Owners own all rights to their own content.</div>
              </div>
          </div>
        
      </div>
    
    
        
    )
}

export default Footer;
