import React, { useState, useEffect } from 'react';
import { collection, getDocs } from "firebase/firestore";
import {db} from '../../firebase';
import { Container, Row, Col } from 'react-bootstrap';

import { useUserAuth } from "../../context/UserAuthContext";

import Popup from 'reactjs-popup';


import TopNav from './MobileTopNav';
import Footer from './MobileFooter';

import './mobile-styles.css';


import socal from '../../assets/images/southerncalifornia.png';
import Instagram from '../../assets/images/instagram.png';
import Facebook from '../../assets/images/facebook.png';


const MobileHome = () => {
  window.scrollTo(0, 0)

  const [liveStatus, setLiveStatus] = useState("");

  const [open, setOpen] = useState(false);  
  const closeModal = () => setOpen(false);

  const fetchLive = async () => {
       
    await getDocs(collection(db, "Controlroom"))
        .then((querySnapshot)=>{               
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
            setLiveStatus(newData[0].MainStageLive);
        })
   
}

useEffect(()=>{
    fetchLive();
}, [])


  const { user } = useUserAuth();
  
  return (

   <Container>
<Row>
    <Col>
   <TopNav />


    <div>
            <div style={{marginLeft:'-45px', marginTop:'25px'}}>
            <img src={socal} alt="southern california local bands and songwriters" height="360px" />
            </div>


    <div style={{textAlign:'center'}}>
    {!user ? (
            <div>
              <div>Welcome to our mobile area. The mobile app is strickly for viewing (live and playlist) and participating in our live shows either by simply using the chat feature or requesting to be part of a one of our live shows. You will need to create an account of login to use the mobile app.</div>
            
            <div style={{display:'flex', justifyContent:'space-evenly', margin:'auto', paddingTop:'6vh', paddingBottom:'25px'}}>
            <div><a href="/mobile-login"  className="mobile-button"> SIGN IN </a></div>
            <div><a href="/mobile-signup"  className="mobile-button"> SIGN UP </a></div>
            </div>
            </div>
    ):(     
      <div>
         <div style={{padding:'35px'}}><a href="/mobile-main-stage" alt="Main streaming stage" style={{backgroundColor:'#002fff', color:'white', padding:'10px', borderRadius:'5px', fontSize:'20px', fontWeight:'bold', letterSpacing: '.1rem'}}> WATCH</a></div>

          <div><a href="/mobile-audiocast" alt="Main streaming stage" style={{backgroundColor:'#002fff', color:'white', padding:'10px', borderRadius:'5px', fontSize:'20px', fontWeight:'bold', letterSpacing: '.1rem'}}> LISTEN </a></div>

          { !liveStatus === true ?
        (
              <div style={{marginTop:'25px'}}>                
             <button type="button" onClick={() => setOpen(o => !o)} style={{color:'white', fontWeight:'bold', backgroundColor:'gray', border:'0px', padding:'5px 7px', paddingBottom:'7px', marginTop:'3px', borderRadius:'5px'}}>WATCH LIVE </button>
            </div>

            ) : ( 
             
              <div style={{marginTop:'35px'}}>                
              <a href="/mobile-live" alt="Main streaming stage" style={{color:'white', fontWeight:'bold', backgroundColor:'red', padding:'10px', borderRadius:'5px'}}>WATCH LIVE </a>
            </div>
           )}
           
      </div>   
    )}                 
   </div>
   <Popup open={open} closeOnDocumentClick onClose={closeModal}>
              <div className="tip-modal">
                <a href="/" className="close" onClick={closeModal}> &times; </a>
            
                <div className="content">Sorry, we are not currently live. The button will change to <span className="text-red-500">red</span> when we are ready or in the process of going live.<br/><br/>Upcoming Live Streams will be announced via our social media pages. Please join us on social media to find out about when we are planning a live event.</div>
         
                <div className="flex justify-center">
         
                <div className="flex justify-center mt-2">
            <span className="text-white rounded-lg h-12 p-2 mr-3">
            <a href="https://www.instagram.com/thestation.tv/" alt="the station tv message us" target="new"><img src={Instagram} alt="The Station TV Instagram" title="The Station TV Instagram" className="icons" /><span className="icons-name">Instagram</span></a>
            </span>
            <span className="text-white rounded-lg h-12 p-2 mr-3">
            <a href="https://www.facebook.com/thestation.tv/" alt="the station tv message us" target="new"><img src={Facebook} alt="The Station TV Facebook" title="The Station TV Facebook" className="icons" /><span className="icons-name">Facebook</span></a>
            </span><br/><br/>
           
          
          </div>
               </div>
               </div>
          </Popup>

          <div style={{textAlign:'center', marginTop:'35px', marginBottom:'15vh'}}>Search For Us On ROKU<br/><span style={{}}>Enter in The Station TV and Add Channel</span></div>
    </div>

    
   <Footer/>
    </Col>
</Row>
    </Container>

  )
}

export default MobileHome;