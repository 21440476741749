// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import{getAuth} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyBrvcepc1HbYqo6obsibr7LcU1BKyBOs08",
  authDomain: "the-station-tv-d7229.firebaseapp.com",
  databaseURL: "https://the-station-tv-d7229-default-rtdb.firebaseio.com",
  projectId: "the-station-tv-d7229",
  storageBucket: "the-station-tv-d7229.appspot.com",
  messagingSenderId: "70693863055",
  appId: "1:70693863055:web:151818e8d5bfc09844bafa",
  measurementId: "G-31D8CWD8G3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export default app;