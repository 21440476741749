import React from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useUserAuth } from "../../context/UserAuthContext";


const MobileFooter = () => {

  const { logOut, user } = useUserAuth();
 // console.log("this is user " + user)

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logOut();
      user = null;
      user.displayName = null;
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

    return (

<div style={{marginTop:'55px'}}>         
<div style={{backgroundColor:'#333', position:'fixed', bottom:'0', left:'0', width:'100%', padding:'15px'}}>
          
          <ul style={{display:'flex', justifyContent:'space-evenly'}}>
            <li style={{alignItems: 'center', fontSize: '1rem'}}>
            <a href="/mobile-join-general" style={{color:'white'}}><i className="fa fa-video-camera" style={{marginLeft:'7px'}}></i></a><br/><span style={{fontSize:'12px'}}>SUBMIT</span>
            </li>
            <li style={{alignItems: 'center', fontSize: '1rem'}}>
            <a href="/mobile-guide" style={{color:'white'}}><i className="fa fa-info-circle" style={{marginLeft:'12px'}}></i></a><br/><span style={{fontSize:'12px'}}>GUIDE</span>
            </li>
            <li style={{alignItems: 'center', fontSize: '1rem'}}>
            <a href="/mobile-about" style={{color:'white'}}><i className="fa fa-briefcase" style={{marginLeft:'7px'}}></i></a><br/><span style={{fontSize:'12px'}}>ABOUT</span>
            </li>
{!user ? (
          
            <li><a href="/mobile-login" style={{color:'white'}}><i className="fa fa-lock" style={{marginLeft:'7px'}}></i></a><br/><span style={{fontSize:'12px'}}>login</span>
            </li>

):(

            <li>
              <Button variant="success" onClick={handleLogout}>
                LOGOUT
              </Button>
            </li>
)}
          </ul>
      </div>
</div>


)}

export default MobileFooter;