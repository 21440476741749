import React from 'react';
import TopNav from "./TopNav";
import Footer from "./Footer";
import { Pricing } from './Pricing';

import "./card.css";
import "./core.css";


const SubmitMedia = () => {
  return (
    <div>
      <TopNav />
      <div className="middle-content extra-top">
        
        <h1>Submit Media</h1></div>

      <h2>Why Join The Station TV Artists &amp; Creators Alliance?</h2>
      
      <p><span style={{color:'orangered'}}>Once you join you will be contacted by our promotional department.</span></p>
      
      <div>
       <Pricing />
      </div>
      

    
<Footer />
</div>

  )
}

export default SubmitMedia