import React, { useState, useRef, useEffect } from 'react';
import { AudioPlayer } from 'react-audio-play';
import "./audiocast.css";

export const AudiocastPlayer = () => {
  window.scrollTo(0, 0)

  const [audioList, setAudioList] = useState([""]);
  const [audioIndex, setAudioIndex] = useState(0);
  const aplayer = useRef();
 
  
 const getTrackData = () => {   
   
  fetch('audiocast.json',{
    headers : { 
      'Content-Type': 'application/json',
      'Accept': 'application/json'
     }
  }).then(function(response){
      return response.json();
    })
    .then(function(myJson) {
      setAudioList(myJson);     
    });
}

useEffect(()=>{
  getTrackData()
},[])

   const getNextAudio = (e) => {
    setAudioIndex(audioIndex + 1);
   }

         
     return (
      <>
      <div className="extra-top">
      <div className="row-container">
          
          
        <div className="row">
          <div className="col"><img src={audioList[audioIndex].thumbnail} alt="slider for artists" width="100%" /></div>
        </div>

        <div className="row">
        
        <div className="col">
          
       <p className="">{audioList[audioIndex].artistName}</p>
           <p>BIO: {audioList[audioIndex].shortDescription}</p></div>
            <div>Location: {audioList[audioIndex].location}<br/>{audioList[audioIndex].socialTag}</div>
            <div className="col" style={{textAlign:'center', padding:'10px'}}>
          <img src={audioList[audioIndex].qrcodeImage} alt="slider for artists" className="" height="152px" />
        </div>              
        </div>

      <div className="row">
            <div className="col">
              <AudioPlayer
              ref={aplayer}
              autoPlay={true}
              src={audioList[audioIndex].audioUrl}
              className="custom-style"
              title="The Station TV - Audiocast"
              width="100%"
              onEnd={getNextAudio}
              >
             
            </AudioPlayer>
           
            </div>              
                             

           {/* <div> Buy Album</div>         */}
      </div>
      </div>
           
                
    </div>
        </>
    );
}

