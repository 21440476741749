import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useUserAuth } from "../../context/UserAuthContext";

import TopNav from "./MobileTopNav";
import Footer from "./MobileFooter";

const MobileSignup = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const { signUp } = useUserAuth();
  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signUp(email, password);
      navigate("/");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
    <TopNav />
    
    <div className="middle-content">
      <h2><b>JOIN THE STATION TV</b></h2>

    <div> 
        <p> Talk to your favorite artist by participating in live shows and interviews via audio or video (please have your room staged before requesting to enter live studio). Compete in contests. Purchase live commerce and more!</p></div>
    
    <p><span style={{color:'red'}}>This is for viewing members only.</span> If you want to be promoted by The Station TV please go to our <a href="/mobile-submit-media" style={{textDecoration:'underline'}}>Submit Media</a> area.</p>
    <p>Let's Sign You Up</p>
      <div className="signup-box">
        
       
        
        {error && <Alert variant="danger">{error}</Alert>}

        <Form onSubmit={handleSubmit}>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <div className="d-grid gap-2">
            <Button variant="secondary" type="Submit">
              Sign up
            </Button>
          </div>
        </Form>
      </div>
      <div className="p-4 box mt-3 text-center">
        Already have an account? <Link to="/mobile-login">Log In</Link>
      </div>

      
      </div>
      

<Footer />
</div>
  );
};

export default MobileSignup;
