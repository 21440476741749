import React, { useState, useRef, useEffect } from 'react';

import TopNav from "./MobileTopNav";
import Footer from "./MobileFooter";
import "./mobile-styles.css";

const MobileGuide = () => {
  window.scrollTo(0, 0)

  const [audioList, setAudioList] = useState([""]); 
  
 const getTrackData = () => {   
   
  fetch('audiocast.json',{
    headers : { 
      'Content-Type': 'application/json',
      'Accept': 'application/json'
     }
  }).then(function(response){
      return response.json();
    })
    .then(function(myJson) {
      setAudioList(myJson);     
    });
}

useEffect(()=>{
  getTrackData()
},[])

     return (
        <div>
            
        <TopNav />
      <div className="extra-top" style={{marginTop:'15px', marginBottom:'125px'}}>
      <div className="row-container">
          <div><h2 style={{textAlign:'center'}}>The Station TV Guide</h2></div>
         
        <div>
        {audioList.map((audio) => ( 
            <div className="row">
          <div><hr size="2"></hr></div>     
        <div>           
          <img src={audio.thumbnail} alt="slider for artists" className="" width="100%" />
        </div>
        <div>
          <p className="">{audio.artistName}</p>
            <p>Song Name: {audio.songName}<br/>Location: {audio.location}<br/>Social Tag: {audio.socialTag}</p>
            <p>BIO: {audio.shortDescription}</p></div>
                  
        </div>
          ))}             
        </div>
                  
                             

         
      </div>
     
      </div>
      <Footer />
      </div>
    );
}

export default MobileGuide;

