import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import TopNav from './MobileTopNav';
import Footer from './MobileFooter';
import Instagram from '../../assets/images/instagram.png';
import Facebook from '../../assets/images/facebook.png';
import Whatsapp from '../../assets/images/whatsapp.png';
import Telegram from '../../assets/images/telegram.png';


const MobileAbout = () => {
    return (

        <Container>
        <Row>
            <Col>
           <TopNav />
    <div className="middle-content">
        
    <h2 style={{fontSize:'20px', fontWeight:'bold', textTransform:'uppercase'}}>About The Station TV</h2>
        
    <div>
    </div> 
       
                
                <div style={{backgroundColor:'#222', padding:'10px'}}>
                    <p style={{fontSize:'18px'}}>Note From The Founder</p>
                       <p style={{fontSize:'16px'}}>I was a struggling artists for years but was lucky enough to find an additional love in coding and the Internet and used it as my creative tool. Through the years I built up my skills in order to achieve what my main objective has been all along. To provide Artists and Creators an area to promote their works of art and be able to keep their revenue. I also realize that there are a number of artist's out there that get so far and then get stuck because they don't know the in's and out's of the business. The Station TV plans to actively promote, solicit and clear up any questions about today's music and entertainment industry for the artists &amp; creators that join The Station TV. As a songwriter, musician and film person, I also plan to use this platform for all my own projects so I will be going through the same process and taking notes that will be shared with our community. I'm just like you, always wondering how or what I can do better to get closer to my goals. Thanks for reading.</p> 
                </div>

                <div>
                       
                       <p style={{fontSize:'18px'}}>The Station TV's 2024 Project Roadmap</p>
                       <ol style={{paddingLeft:'25px'}}>
                       <li style={{listStyleType:'inherit', fontSize:'18px'}}>Start IRL streaming locally with mobile kit.<p style={{fontSize:'16px', fontStyle:'italic'}}>Starting with the Southern California area stream live with mobile kit. Expand out from there.</p></li>
                       <li style={{listStyleType:'inherit', fontSize:'18px', paddingTop:'15px'}}>Provide an area for artists to sell their songs and keep 100% of revenue.<p style={{fontSize:'16px', fontStyle:'italic'}}>We had this earlier, we just need to redo it.</p></li>
                       <li style={{listStyleType:'inherit', fontSize:'18px', paddingTop:'15px'}}>Provide a live commerce area for anyone as well as artists to sell their songs, albums, published works, books and keep 100% of revenue.<p style={{fontSize:'16px', fontStyle:'italic'}}>We have this crazy idea! Let's go back to the days of Vinyl! We plan to hook up with vinyl manufacturers in the area and send the deals your way. Put together a package for your fans that include the full vinyl album with band merch and sell it via our live commerce while talking with your fans. Talk to your fans, play a bit of the tune, sell baby SELL!!</p></li>
                       <li style={{listStyleType:'inherit', fontSize:'18px', paddingTop:'15px'}}>Optain a PRO's account in order to solicit artists songs to radio stations, venues, film/tv or wherever people publish artists.<p style={{fontSize:'16px', fontStyle:'italic'}}>We plan to add to our admin section a Private Login for people looking for music so they can listen to what you have, download your EPK and contact the artist.</p></li>
                       <li style={{listStyleType:'inherit', fontSize:'18px', paddingTop:'15px'}}>Create a membership area for the Roku Channel.</li>
                       <li style={{listStyleType:'inherit', fontSize:'18px', paddingTop:'15px'}}>Start production on contest and interactive shows.</li>
                       </ol>
             
            
    </div>
<p style={{marginTop:'25px', fontSize:'24px', textAlign:'center'}}><a href="/mobile-submit-media" style={{textDecoration:'underline'}} >SUBMIT YOUR MEDIA TO THE STATION TV</a></p>
          <h4 className="text-center uppercase mt-6">To contact us or to see who's going live, join our socials:</h4>
          <div className="text-center p-4">
          <div className="flex justify-center mt-2">
            <span className="text-white hover:bg-gradient-to-r hover:from-cyan-500 hover:to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg h-12 p-2 mr-3">
            <a href="https://www.instagram.com/thestation.tv/" alt="the station tv message us" target="new"><img src={Instagram} alt="The Station TV Instagram" title="The Station TV Instagram" className="icons" /><span className="icons-name">Instagram</span></a>
            </span>
            <span className="text-white hover:bg-gradient-to-r hover:from-cyan-500 hover:to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg h-12 p-2 mr-3">
            <a href="https://www.facebook.com/thestation.tv/" alt="the station tv message us" target="new"><img src={Facebook} alt="The Station TV Facebook" title="The Station TV Facebook" className="icons" /><span className="icons-name">Facebook</span></a>
            </span><br/><br/>
            <span className="text-white hover:bg-gradient-to-r hover:from-cyan-500 hover:to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg h-12 p-2 mr-3">
            <a href="https://wa.me/17145972178" alt="the station tv message us" target="new"><img src={Whatsapp} alt="The Station TV Facebook" title="The Station TV Facebook" className="icons" /><span className="icons-name">Whatsapp</span></a>
            </span>
            <span className="text-white hover:bg-gradient-to-r hover:from-cyan-500 hover:to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg h-12 p-2 mr-3">
            <a href="https://t.me/+6EW5A2HM378yNTlh" alt="the station tv message us" target="new"><img src={Telegram} alt="The Station TV Facebook" title="The Station TV Facebook" className="icons" /><span className="icons-name">Telegram</span></a>
            </span>
          </div>
          </div>
   <p style={{textAlign:'center', fontSize:'22px'}}> or you can email us at:<br/> <a href="media@thestationtv.com">media@thestationtv.com</a></p>
   </div>
  
 
    <div style={{borderTop:'1px solid #333333'}}>
       <p style={{fontSize:'18px'}}>What is a QR Code and how do I scan it?</p>
       <p>QR Codes have been popular in Europe for a very long time and I got the idea when traveling to Germany. It never really took off in the states until recently. I am seeing them pop up everywhere which gave me the idea to create a custom QR Code for all the Artists &amp; Creators that provide content for The Station TV's playlist and have the QR Code send the user to where ever they want. To purchase their songs, albums, books, works of art, or even simply their website. Then I thought I'd take it one step further and provide them with a Tip Jar.</p>
       <p style={{paddingTop: '10px', fontFamily:'verdana', color:'orangered', marginBottom:'15vh'}}>To scan the QR Code simply download any free qrcode scanner on your mobile device, point it at the QR Code and scan. The app will either send you directly to the link or will provide a link for you. It's that simple.</p>
       
       
  </div>
 <Footer /> 
 </Col>
</Row>
</Container>
)
}

export default MobileAbout;