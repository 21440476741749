import React, { useEffect, useState } from "react";
import Topbar from './MobileTopNav';
import Footer from './MobileFooter';
//import "../core.css";

//import VideoPlaylist from "./playlist";

const MobileMainStage = () => {
    window.scrollTo(0, 0)

    const [videoList, setVideoList] = useState([""]);
    const [videoIndex, setVideoIndex] = useState(0);
    
   const getTrackData = () => {   
     
    fetch('playlist.json',{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }).then(function(response){
        return response.json();
      })
      .then(function(myJson) {
        setVideoList(myJson);     
      });
  }

  useEffect(()=>{
    getTrackData()
},[])

  //  const getNextIndex = () => {
  //    console.log("inside get next index")
  //    return videoIndex + 1 <videoList.length ? videoIndex + 1 : 0;
     
  //    }

     const getNextVideo = (e) => {
      setVideoIndex(videoIndex + 1);
     }


    return (
      <>
      <Topbar />
      <div className="middle-content">
      <div>
      <div style={{textAlign:'center'}}>
            <video
              src={videoList[videoIndex].videoUrl}
              id="video-box"
              title="The Station TV - thestation.tv - Main Stage"
              width="100%"
              scrolling="no"
              playsInline
              autoPlay={true}
              controls={true}
              controlsList="nodownload"
              onEnded={getNextVideo}
              >
            </video>
        <div className="ad-area">
                                
                                  <div className="ad-area-inside-left">
                                    <div style={{fontSize:'14px'}}><a href="mailto:media@thestationtv.com" alt="contact us" style={{color:'white'}}>Your Ad Here - Contact Us</a></div>
                                    </div> 
                                  {/* <div >
                                    <div style={{textTransform:'uppercase'}}><img src={videoList[videoIndex].qrcodeImage} alt="tip jar for artists" height="200px" /></div>
                                  </div> */}
                                </div>
                   
      
        </div>

        <div>
        
        {/* <div>
          <div>
            <div style={{marginRight:'15px'}}><img src={videoList[videoIndex].thumbnail} alt="slider for artists" width="330" /></div>
           
                    </div>

          </div> */}
       
                                           
                     
                    
                      
                          
                      <div style={{marginBottom:'150px'}}>
                          <div className="pt-2"> 
                              <div className="">  
                              <div><h2 className="">{videoList[videoIndex].artistsName}</h2></div> 
                              <div className="bio">BIO: {videoList[videoIndex].description}</div>
                              <div style={{paddingTop:'15px'}}>Location: {videoList[videoIndex].location}</div>
                              <div>{videoList[videoIndex].email}</div>
                              <div>{videoList[videoIndex].socialTag}</div>
                              </div>

                           
      
                             
                    </div>
                   

                   
      </div>
      </div>
           
                  

            
            </div>
            
    </div>
{/* 
    <VideoPlaylist /> */}
    {/* </div> */}
   
        <Footer />
        </>
    );
}

export default MobileMainStage;