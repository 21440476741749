/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import TopNav from "./TopNav";
import "./core.css";

import data from "./playlist.json";

//console.log("this is data ", data)

const Details = () => {
  let { videoId } = useParams(); // this works and gives me the index from ListItem

  return (
    <>
      <TopNav />
      <div className="middle-content extra-top">
      <div className="video-container">
      {data.map((video) => video.id === videoId && (
  
          <div className="grid grid-cols-3 gap-2 pb-40">
            {console.log("this is video ", video.id)}
            <div style={{textAlign:'center'}}>
            <video
              src={video.videoUrl}
              id="video-box"
              title="The Station TV - thestation.tv - Main Stage"
              width="100%"
              scrolling="no"
              playsInline
              autoPlay={true}
              controls={true}
              controlsList="nodownload"
              >
            </video>
         
          </div>
        
                                           
          <div className="row">
          <div className="col">
          <div className="col-2">
            <div><img src={video.thumbnail} alt="slider for artists" className="" height="500vw" /></div>
          </div>
          </div>
                  {video.category === "artist" ? (
                      <div className="col">
                          <div className="pt-2"> 
                              <div>  
                              <div><h2 className="">{video.artistsName}</h2></div> 
                              <div>Song Name: {video.songName}</div>
                              <div className="bio">BIO: {video.description}</div>
                              <div style={{paddingTop:'15px'}}>Location: {video.location}</div>
                              <div>{video.email}</div>
                              <div><a href={video.tagUrl} className="links" style={{color:'white'}}>{video.socialTag}</a></div>
                              
                              </div>
 
                          </div>

                          <img src={video.qrcodeImage} className="qrcodeimage" alt={video.showName} />
                      </div>  
                  ) : (
                    <div className="col">
                          <div className="pt-2"> 
                              <div>
                              <div><h2 className="">{video.artistsName}</h2></div> 
                              <div>Song Name: {video.songName}</div>
                              <div className="bio">BIO: {video.description}</div>
                              <div style={{paddingTop:'15px'}}>Location: {video.location}</div>
                              <div>{video.email}</div>
                              <div><a href={video.tagUrl} className="links" style={{color:'white'}}>{video.socialTag}</a></div>
                              
                              </div>
 
                          </div>

                          <div><img src={video.qrcodeImage} className="qrcodeimage" alt={video.showName} /></div>
                          <div>{video.extras}</div>
                      </div>  
                  )
                }
             
                    </div>
      </div>
      ))}
      </div>
      </div>
      <Footer />
    </>
  );
};

export default Details;
